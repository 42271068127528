import React from 'react';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import ReactVisibilitySensor from 'react-visibility-sensor';
import ReactPlayer from 'react-player';
import clsx from 'clsx';
import 'objectFitPolyfill';
import { graphql } from 'gatsby';

import * as styles from './image.module.scss';

type ImageComponentProps = Pick<GatsbyImageProps, 'objectFit'> & {
  gatsbyImageData: IGatsbyImageData;
  alt: string;
  className?: string;
  onLoad?: () => void;
};

const ImageComponent: React.FC<ImageComponentProps> = ({
  gatsbyImageData,
  alt,
  className,
  objectFit,
  onLoad,
}) => {
  return (
    <GatsbyImage
      className={className}
      objectFit={objectFit}
      onLoad={onLoad}
      onStartLoad={({ wasCached }) => {
        if (wasCached && onLoad) onLoad();
      }}
      image={gatsbyImageData}
      alt={alt}
    />
  );
};

type VideoComponentProps = {
  url: string;
  className?: string;
  onLoad?: () => void;
};

const VideoComponent: React.FC<VideoComponentProps> = ({ url, className, onLoad }) => {
  const [isVisible, setVisible] = React.useState<boolean>(false);

  const handleVisible = React.useCallback<(visible: boolean) => void>((visible) => {
    setVisible(visible);

    return () => setVisible(false);
  }, []);

  return (
    <ReactVisibilitySensor onChange={handleVisible} partialVisibility offset={{ bottom: 100 }}>
      <ReactPlayer
        className={clsx(styles.video, className)}
        pip={false}
        url={url}
        muted
        playsinline
        onReady={() => {
          if (onLoad) onLoad();
          // IE11 Polyfill for object-fit on video element
          const vids = Array.from(document.querySelectorAll(`.${styles.video} video`));
          if (vids.length > 0) {
            vids.forEach((vid) => {
              vid.setAttribute('data-object-fit', '');
            });
            window.objectFitPolyfill();
          }
        }}
        playing={isVisible}
        loop={true}
        width={'100%'}
        height={'100%'}
      />
    </ReactVisibilitySensor>
  );
};

export type ImageProps = Pick<GatsbyImageProps, 'objectFit'> &
  Partial<
    Omit<GatsbyTypes.DatoCmsFileField, 'gatsbyImageData'> & {
      gatsbyImageData: IGatsbyImageData;
      className: string;
      onLoad?: () => void;
    }
  >;

const Image: React.FC<ImageProps> = ({
  isImage,
  mimeType = '',
  gatsbyImageData,
  alt,
  url,
  onLoad,
  objectFit,
  className,
}) => {
  if (isImage && gatsbyImageData) {
    return (
      <ImageComponent
        className={className}
        onLoad={onLoad}
        objectFit={objectFit}
        gatsbyImageData={gatsbyImageData}
        alt={alt ?? ''}
      />
    );
  } else if (!isImage && /^video/.test(mimeType) && url) {
    return <VideoComponent className={className} onLoad={onLoad} url={url} />;
  }

  return null;
};

export default Image;

export const query = graphql`
  fragment Image on DatoCmsFileField {
    isImage
    mimeType
    gatsbyImageData(placeholder: DOMINANT_COLOR)
    alt
    url
  }
`;
